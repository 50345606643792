/**
 * Viewport Mixin
 *
 * Provides responsive viewport information to Vue components.
 * Handles window resize events with debounced updates to prevent performance issues
 * when multiple components use this mixin simultaneously.
 *
 * Usage:
 * import viewportMixin from "./mixin.viewport";
 * export default {
 *   mixins: [viewportMixin]
 * }
 *
 * Available properties:
 * - viewport.isMobile: true when width < 768px
 * - viewport.isTablet: true when width >= 768px && < 1024px
 * - viewport.isDesktop: true when width >= 1024px
 * - viewport.width: current viewport width
 * - viewport.height: current viewport height
 * - viewport.isClient: true when running in browser (for SSR detection)
 */

// Dependencies
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      viewport: {
        width: 0,
        height: 0,
        isMobile: true,
        isTablet: false,
        isDesktop: false,
        isClient: false
      }
    };
  },

  beforeMount() {
    // Handle SSR - check if we're running in browser
    this.viewport.isClient = typeof window !== "undefined";

    if (this.viewport.isClient) {
      // Set initial viewport dimensions and breakpoints
      this.updateViewport();
    }
  },

  mounted() {
    if (this.viewport.isClient) {
      // Start listening for window resize events
      window.addEventListener("resize", this.debouncedUpdateViewport);
    }
  },

  beforeDestroy() {
    if (this.viewport.isClient) {
      // Clean up event listener
      window.removeEventListener("resize", this.debouncedUpdateViewport);
    }
  },

  methods: {
    updateViewport() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Update viewport data with current dimensions and breakpoint flags
      this.viewport = {
        ...this.viewport,
        width,
        height,
        isMobile: width < 768, // Bootstrap 4 breakpoint
        isTablet: width >= 768 && width < 1024,
        isDesktop: width >= 1024
      };
    },

    // Debounce resize updates to prevent performance issues when multiple
    // components use this mixin. Primary use cases for viewport changes:
    // - Mobile: Device rotation, browser ui changes
    // - Desktop: Window resizing, browser zoom, moving between displays
    debouncedUpdateViewport: debounce(function() {
      this.updateViewport();
    }, 200)
  }
};
