<template>
  <header
    :class="[
      'v-hero-custom',
      {
        'fullscreen-mobile': fullscreenMobile,
        'full-height-desktop': fullHeightDesktop,
        'full-width-desktop': fullWidthDesktop,
        'video-embed': video && !hasValidImage
      }
    ]"
    :style="computedStyles"
  >
    <picture v-if="image && (resolveUrl(imgSrc) || resolveUrl(imgMdSrc))">
      <source
        :srcset="resolveUrl(imgSrc) || resolveUrl(imgMdSrc)"
        media="(max-width: 767px)"
      />
      <source
        :srcset="resolveUrl(imgMdSrc) || resolveUrl(imgSrc)"
        media="(min-width: 768px)"
      />
      <img
        :src="resolveUrl(imgSrc) || resolveUrl(imgMdSrc)"
        :alt="imgAltText"
      />
    </picture>
    <div :class="['video-embed']" v-if="video">
      <section class="video-embed--mobile">
        <slot name="video-mobile"></slot>
      </section>
      <section class="video-embed--desktop">
        <slot name="video-desktop"></slot>
      </section>
    </div>
    <div
      v-if="useOverlay && overlayColor && overlayAlpha !== null"
      class="color-overlay"
    ></div>
    <article>
      <div :class="['content-container', alignmentClass]">
        <!-- Title container - only shown if content exists for current device -->
        <div
          v-if="
            (viewport.isMobile && $slots['title-mobile']) ||
              (!viewport.isMobile && $slots['title-desktop'])
          "
          :class="['title-container', { 'text-shadow': textShadow }]"
        >
          <template v-if="viewport.isMobile">
            <slot name="title-mobile"></slot>
          </template>
          <template v-else>
            <slot name="title-desktop"></slot>
          </template>
        </div>

        <!-- Text container - only shown if content exists for current device -->
        <div
          v-if="
            (viewport.isMobile && $slots['text-mobile']) ||
              (!viewport.isMobile && $slots['text-desktop'])
          "
          :class="['text-container', { 'text-shadow': textShadow }]"
        >
          <template v-if="viewport.isMobile">
            <slot name="text-mobile"></slot>
          </template>
          <template v-else>
            <slot name="text-desktop"></slot>
          </template>
        </div>
        <div
          :class="[
            'button-container',
            { 'text-shadow': textShadow },
            { 'transparent-buttons': transparentButtons }
          ]"
        >
          <slot name="button"></slot>
        </div>
      </div>
    </article>
  </header>
</template>

<script>
import viewportMixin from "../../../mixin.viewport";

export default {
  name: "VHeroCustom",
  mixins: [viewportMixin],
  props: {
    fullscreenMobile: {
      type: Boolean,
      default: false
    },
    fullHeightDesktop: {
      type: Boolean,
      default: false
    },
    fullWidthDesktop: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    textShadow: {
      type: Boolean,
      default: false
    },
    transparentButtons: {
      type: Boolean,
      default: false
    },
    minHeightDesktop: {
      type: String
    },
    vAlignMobile: {
      type: String,
      default: "bottom"
    },
    vAlignDesktop: {
      type: String,
      default: "center"
    },
    alignMobile: {
      type: String,
      default: "left"
    },
    alignDesktop: {
      type: String,
      default: "left"
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgMdSrc: {
      type: String,
      default: null
    },
    imgAltText: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String
    },
    useOverlay: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: null
    },
    overlayAlpha: {
      type: Number,
      default: 0.5
    },
    titleColor: {
      type: String
    },
    textColor: {
      type: String
    },
    buttonBgColor: {
      type: String
    },
    button2BgColor: {
      type: String
    },
    buttonColor: {
      type: String
    },
    button2Color: {
      type: String
    }
  },
  computed: {
    mappedVAlignMobile() {
      const mapping = {
        bottom: "flex-end",
        top: "flex-start",
        center: "center"
      };
      return mapping[this.vAlignMobile] || this.vAlignMobile;
    },
    mappedVAlignDesktop() {
      const mapping = {
        bottom: "flex-end",
        top: "flex-start",
        center: "center"
      };
      return mapping[this.vAlignDesktop] || this.vAlignDesktop;
    },
    alignmentClass() {
      return `align-mobile--${this.alignMobile} align-desktop--${this.alignDesktop}`;
    },
    computedStyles() {
      return {
        "--bg-color": this.bgColor,
        "--overlay-color": this.rgbaOverlayColor,
        "--align-content-desktop": this.alignDesktop,
        "--v-align-content-mobile": this.mappedVAlignMobile,
        "--v-align-content-desktop": this.mappedVAlignDesktop,
        ...(this.minHeightDesktop
          ? { "--height-desktop": this.minHeightDesktop + "px" }
          : {}),
        "--title-color": this.titleColor,
        "--text-color": this.textColor,
        "--button-bg-color": this.buttonBgColor,
        "--button-color": this.buttonColor,
        "--button-2-bg-color": this.button2BgColor,
        "--button-2-color": this.button2Color
      };
    },
    rgbaOverlayColor() {
      if (this.overlayColor && this.overlayAlpha !== null) {
        const rgb = this.hexToRgb(this.overlayColor);
        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${this.overlayAlpha})`;
      }
      return null;
    },
    hasValidImage() {
      return this.resolveUrl(this.imgSrc) || this.resolveUrl(this.imgMdSrc);
    }
  },
  methods: {
    resolveUrl(url) {
      if (!url) {
        return null;
      }
      if (/^(?!https?|\:\/\/)/.test(url) && typeof document !== "undefined") {
        // Create link element
        const link = document.createElement("a");
        // Set relative
        link.href = url;
        // Return resolved URL
        return link.href;
      }
      // Fallback to provided URL
      return url;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";
@import "./v-hero-custom.scss";
</style>
