<template>
  <div
    :class="[
      'v-input',
      `v-input__${tag}`,
      autoSize && 'v-input--grow',
      !!disabled && 'v-input--disabled',
      !!small && 'v-input--small',
      !!tooltip && 'v-input--tooltip',
      showError && (!!error || !!errorSlot) && 'v-input--error',
      { 'v-input--filled': !!localValue || !!autoFill }
    ]"
  >
    <label class="v-input__wrapper">
      <input
        v-if="isInput"
        ref="formElement"
        class="v-input__element"
        v-bind="$attrs"
        v-on="$listeners"
        @invalid="onInvalid"
        @input="onInput"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
        @paste="onPaste"
        @blur="localValue = sanitizeValue(localValue)"
        :value="localValue"
        :autocomplete="$attrs.autocomplete || 'on'"
        :disabled="disabled"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <textarea-autosize
        v-else
        ref="formElement"
        rows="1"
        :min-height="minHeight"
        :max-height="maxHeight"
        :autosize="autoSize"
        :disabled="disabled"
        class="v-input__element"
        v-bind="$attrs"
        @invalid.native="onInvalid"
        @input.native="onInput($event, true)"
        @keydown.native="onKeyDown"
        @keyup.native="onKeyUp"
        @paste.native="onPaste"
        @blur.native="localValue = sanitizeValue(localValue)"
        :value="localValue || ''"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <div class="v-input-outline" tabindex="-1">
        <div class="v-input-outline__leading"></div>
        <div class="v-input-outline__notch">
          <span class="v-input__label">{{ placeholder }}</span>
        </div>
        <div class="v-input-outline__trailing"></div>
      </div>
    </label>
    <div
      v-if="clearButton && localValue"
      @click.prevent.stop="onReset()"
      class="v-input__clear"
    >
      <div class="v-input__clear__icon">
        <v-icon name="x" width="8" />
      </div>
    </div>
    <div
      tabindex="-1"
      v-if="showError || description"
      :class="['v-input__helper']"
    >
      {{ showError ? error || errorSlot : description }}
    </div>
    <div tabindex="-1" v-if="!mounted" class="v-input__slot" ref="error">
      <slot v-if="!!this.$slots.default"></slot>
      <slot v-else name="error"></slot>
    </div>
    <v-infoicon v-if="tooltip" :tooltip="tooltip"></v-infoicon>
  </div>
</template>

<script>
import { default as helper } from "../../helpers.js";
import { default as resetMixin } from "../v-form/mixin.reset";

export default {
  inheritAttrs: false,
  mixins: [resetMixin],
  props: {
    strict: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: "input"
    },
    placeholder: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    autoSize: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    cpr: {
      type: Boolean,
      default: false
    },
    dateInput: {
      type: String,
      default: null,
      validator: val => ["date", "month", "year"].includes(val)
    },
    clearButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.$props.value,
      numberFilter: /[^\d]/,
      isInput: this.$props.tag.toUpperCase() === "INPUT",
      errorSlot: false,
      mounted: false,
      showError: false,
      autoFill: false
    };
  },
  created() {
    this.showError = this.invalid !== false;
    this.localValue = this.sanitizeValue(this.$props.value);
  },
  mounted() {
    helper.setInputMinlength(this.$refs.formElement);

    const error = this.$refs.error.innerText;

    if (error) {
      this.errorSlot = error.trim();
      this.showError = true;
    }

    this.mounted = true;
    this.$nextTick(() => {
      const el = document.getElementById("autofill");
      if (el) {
        setTimeout(() => {
          if (el.matches(":-internal-autofill-selected")) {
            this.autoFill = true;
          }
        }, 1000);
      }
    });
  },
  methods: {
    onReset() {
      this.localValue = "";
      this.$emit("reset");
    },
    onKeyDown(event) {
      // Handle CPR input separately
      if (this.cpr) return this.onKeyDownCPR(event);
      // Handle date input seperatly
      if (this.dateInput) return this.onKeyDownDate(event);

      // Prevent specific key input but allow backspace and arrows
      if (this.strict && this.$attrs.type === "number") {
        const allowedKeys = [
          "Backspace",
          "Delete",
          "ArrowLeft",
          "ArrowRight",
          "Tab"
        ];
        if (
          !allowedKeys.includes(event.key) &&
          this.numberFilter.test(event.key)
        ) {
          event.preventDefault();
        }
      }
    },
    onKeyDownCPR(event) {
      // Prevent anything that's not a digit, backspace or arrows
      if (
        ![8, 9, 37, 39].includes(event.keyCode) &&
        /[^\d\-]/.test(event.key)
      ) {
        event.preventDefault();
      }

      // Allow max 10 digits
      if (/\d/.test(event.key)) {
        if ((event.target.value.match(/\d/g) || []).length === 10) {
          event.preventDefault();
        }
      }

      // Allow max 1 hyphen
      if (event.key === "-") {
        if (event.target.value.includes("-")) {
          event.preventDefault();
        }
      }
    },
    onKeyDownDate(event) {
      // Prevent anything that's not a digit, backspace or arrows
      if (
        ![8, 9, 37, 39].includes(event.keyCode) &&
        /[^\d\-]/.test(event.key)
      ) {
        event.preventDefault();
      }

      // We want to be able to jump back to previous date input with backspace
      if (event.keyCode == 8) {
        const element = event.target;
        const index = this.dateInput == "year" ? 1 : 0;
        const length = 0;
        if (element.value.length == length && this.dateInput != "date") {
          const sibling =
            element.parentNode.parentNode.parentNode.parentNode.children[index];
          const nextInput = sibling.querySelector("input");
          nextInput.focus();
        }
      }

      // Prevent input after certain length
      const length =
        this.dateInput == "date" ? 2 : this.dateInput == "month" ? 2 : 4;
      if (/\d/.test(event.key)) {
        if ((event.target.value.match(/\d/g) || []).length === length) {
          event.preventDefault();
        }
      }
    },
    onKeyUp(event) {
      // Validate minlength attribute using JS to prevent
      // counting excessive whitespaces as chars
      if (this.$attrs.minlength) {
        const valueWithoutSpace = event.target.value
          .trim()
          .replace(/\s+/g, " ");

        const formElement = this.isInput
          ? this.$refs.formElement
          : this.$refs.formElement.$el;

        if (valueWithoutSpace.length < this.$attrs.minlength) {
          formElement.setCustomValidity("min-length-error");
        } else {
          formElement.setCustomValidity("");
        }
      }
    },
    onPaste(event) {
      const txt = event.clipboardData.getData("Text");

      if (
        !!this.$attrs.type &&
        this.$attrs.type === "number" &&
        this.numberFilter.test(txt)
      ) {
        event.preventDefault();
      }
    },

    sanitizeValue(value) {
      if (!value) return value;
      return value.replace(/[<>]/g, "");
    },

    onInput(event, emit = false) {
      event.preventDefault();

      // Handle date inputs and automatically jump to next input
      if (this.dateInput) {
        const element = event.target;
        const index = this.dateInput == "date" ? 1 : 2;
        const length = 2;
        if (element.value.length == length && this.dateInput != "year") {
          const sibling =
            element.parentNode.parentNode.parentNode.parentNode.children[index];
          const nextInput = sibling.querySelector("input");
          nextInput.focus();
        }
      }

      // insert hyphen if CPR
      if (this.cpr) {
        if (event.target.value.length === 6 && event.data !== null) {
          event.target.value += "-";
          this.localValue = event.target.value;
        }
      }

      // Hide error if shown
      if (this.showError) {
        this.showError = false;
      }

      // Update and sanitize value
      this.localValue = this.sanitizeValue(event.target.value);
      event.target.value = this.localValue; // Update the input value to match sanitized value

      // Should we emit event?
      !!emit && this.$emit("input", event);
    },
    onInvalid(event) {
      event.preventDefault();
      this.localValue = event.target.value.trim();
      this.showError = true;
    }
  },
  watch: {
    value(value) {
      this.localValue = this.sanitizeValue(value);
    },
    invalid(invalid) {
      this.showError = invalid !== false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-input {
  display: inherit;
  position: relative;

  &__clear {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 3px;
    padding: 12px 10px 10px 10px;
    z-index: 50;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: theme-color("white");
      background-color: theme-color("border");
      cursor: pointer;
      transition: background-color 200ms ease-out;
      border-radius: 50%;

      &:hover {
        background-color: darken(theme-color("border"), 10%);
      }
    }
  }

  &__textarea {
    height: 150px;
  }

  &--grow {
    height: auto;
  }

  &__slot {
    .v-input__input & {
      display: none;
    }
  }

  &__wrapper {
    border-radius: 10px;
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: theme-color("white");

    input:autofill {
      color: red;
    }

    .v-input__textarea:not(.v-input--grow) & {
      padding-right: 1px;
      padding-bottom: 10px;
    }
  }

  &-outline {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 0 solid theme-color("border");

    .v-input--disabled & {
      border-color: theme-color("disabled");
    }

    &__leading,
    &__notch,
    &__trailing {
      height: 100%;
      border: 1px solid;
      border-color: inherit;
    }

    &__leading {
      border-right: none;
      border-radius: 5px 0 0 5px;
      width: 12px;

      .v-input--small & {
        width: 4px;
      }
    }

    &__notch {
      flex: 0 0 auto;
      width: auto;
      border-left: none;
      border-right: none;
      max-width: calc(100% - 12px * 2);
    }

    &__trailing {
      border-left: none;
      border-radius: 0 5px 5px 0;
      flex-grow: 1;
    }

    .v-input--filled.v-input--error &,
    .v-input--error & {
      border-color: theme-color("danger");
      color: theme-color("danger");
    }
  }

  &__label {
    display: inline-block;
    position: relative;
    top: 13px;
    right: auto;
    bottom: auto;
    max-width: 100%;
    color: theme-color("placeholder");
    background: transparent;
    padding: 0 5px;
    transform-origin: left top;
    transition: all 200ms ease-out;
    cursor: text;
    pointer-events: none;
    white-space: nowrap;

    .v-input--error & {
      color: theme-color("danger");
    }

    .v-input--small & {
      top: 4px;
      font-size: 0.875rem;
    }

    .v-input--disabled & {
      color: theme-color("border");
    }
  }

  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-input--error & {
      color: theme-color("danger");
    }
  }

  &__element {
    width: 100%;
    font-size: 1rem;
    z-index: 1;
    border: none !important;
    outline: 0;
    background: transparent;
    transition: all 200ms ease-out;
    font-family: "Lota Grotesque", Arial, Helvetica, sans-serif;

    .v-input__textarea & {
      height: 100%;
      padding: 12px 10px;
      margin-top: 6px;
      line-height: 1.5em;
      overflow: auto;
      resize: none;
    }

    .v-input--grow & {
      overflow: hidden;
      height: 50px;
      min-height: 29px;
      padding: 2px 5px;
      margin: 10px 5px;
    }

    .v-input__input & {
      height: 50px;
      padding: 0 10px;
      line-height: 1em;

      // IE11/Edge specific
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }

      &:invalid {
        box-shadow: none;
      }
    }

    .v-input--small & {
      height: 34px;
      font-size: 0.875rem;
    }

    .v-input--tooltip & {
      margin-right: 40px;
    }

    &:disabled {
      cursor: not-allowed;
      /* required on iOS */
      -webkit-text-fill-color: theme-color("border");
      opacity: 1;

      .v-input__input & {
        color: theme-color("border");
      }

      .v-input__textarea & {
        color: theme-color("border");
      }
    }

    &:disabled ~ .v-input-outline .v-input__label {
      cursor: not-allowed;
    }

    &:focus ~ .v-input-outline {
      border-color: theme-color("focus");
      //box-shadow: 0 0 0 1px theme-color("focus");

      .v-input--error & {
        border-color: theme-color("danger");
        //box-shadow: 0 0 0 1px theme-color("danger");
      }

      .v-input-outline__notch {
        border-top: none;
      }

      .v-input__label {
        font-size: 0.75rem;
        transform: translateY(-25px);
        color: theme-color("focus");

        .v-input--error & {
          color: theme-color("danger");
        }

        .v-input--small & {
          transform: translateY(-18px);
        }
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.v-input--filled .v-input-outline {
    .v-input-outline__notch {
      border-top: none;
    }
    .v-input__label {
      font-size: 0.75rem;
      transform: translateY(-25px);
    }
  }

  &.v-input--filled.v-input--small .v-input-outline {
    .v-input__label {
      transform: translateY(-18px);
    }
  }
}
</style>
