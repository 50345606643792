<template>
  <div class="v-tabs">
    <template v-for="tab in tabs">
      <v-anchor v-if="tab.id" :id="tab.id" :key="`v-anchor-${tab.id}`" />
    </template>
    <div :class="['v-tabs__titles', overflow && 'v-tabs__titles--overflow']">
      <div class="v-tabs__titles__wrapper" ref="titles">
        <component
          v-for="(tab, index) in tabs"
          v-bind:is="tab.isLink ? 'a' : 'div'"
          v-bind="tab.$attrs"
          v-bind:key="'tab-' + index"
          :class="['v-tabs__title', { 'v-tabs__title--active': tab.isActive }]"
          @click="!tab.isLink && selectTab(index)"
          >{{ tab.title }}</component
        >
      </div>
    </div>
    <div class="v-tabs__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      overflow: false
    };
  },
  methods: {
    selectTab(activeIndex) {
      this.tabs.forEach((tab, index) => {
        tab.isActive = activeIndex === index;
      });
      this.$emit("change", {
        name: this.tabs[activeIndex].title,
        index: activeIndex
      });
      this.setUrlHash(this.tabs[activeIndex].id);
    },
    setUrlHash(tabId) {
      if (!tabId) return;
      const location = window.location.href.split("#")[0];
      window.history.replaceState(null, null, `${location}#${tabId}`);
    },
    setActiveTab() {
      // Find the first active tab
      const activeTab = this.tabs.find(tab => tab.isActive);

      // Make sure only that tab is active
      this.tabs.forEach((tab, index) => {
        tab.isActive = tab === activeTab;
      });

      // Fall back to the first tab
      if (!activeTab && this.tabs.length > 0) {
        this.tabs[0].isActive = true;
      }
    },
    detectOverflow() {
      const titles = this.$refs.titles;
      if (titles.scrollWidth > titles.offsetWidth) {
        this.overflow = true;
      }
    }
  },
  mounted() {
    this.tabs = this.$children;

    this.tabs = this.tabs.map(tab => {
      tab.isLink = !!tab.$attrs.href;
      return tab;
    });

    this.setActiveTab();
    this.$nextTick(this.detectOverflow);
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-tabs {
  &__titles {
    &__wrapper {
      display: flex;
      overflow-x: auto;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 1px;
    }

    &--overflow {
      position: relative;
      margin-right: -$spacer;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 80px;
        top: 0;
        right: 0;
        pointer-events: none;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      & .v-tabs__titles__wrapper:after {
        content: "";
        padding-right: 80px;
      }
    }

    @media print {
      display: none;
    }
  }

  &__title {
    position: relative;
    top: 1px;
    white-space: nowrap;
    padding-bottom: $spacer * 0.5;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    user-select: none;
    color: theme-color("black");
    text-decoration: none !important;

    & + & {
      margin-left: $spacer * 2;
    }

    &--active {
      font-weight: normal;
      color: darken(theme-color("primary"), 7%);
      border-color: theme-color("primary");
    }
  }

  &__content {
    color: theme-color("black");

    :deep(.text-placeholder) {
      color: theme-color("placeholder") !important;
    }
  }

  @media print {
    margin-top: 0 !important;
  }
}
</style>
