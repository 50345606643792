var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
    'v-hero-custom',
    {
      'fullscreen-mobile': _vm.fullscreenMobile,
      'full-height-desktop': _vm.fullHeightDesktop,
      'full-width-desktop': _vm.fullWidthDesktop,
      'video-embed': _vm.video && !_vm.hasValidImage
    }
  ],style:(_vm.computedStyles)},[(_vm.image && (_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc)))?_c('picture',[_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc),"media":"(max-width: 767px)"}}),_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgMdSrc) || _vm.resolveUrl(_vm.imgSrc),"media":"(min-width: 768px)"}}),_c('img',{attrs:{"src":_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc),"alt":_vm.imgAltText}})]):_vm._e(),(_vm.video)?_c('div',{class:['video-embed']},[_c('section',{staticClass:"video-embed--mobile"},[_vm._t("video-mobile")],2),_c('section',{staticClass:"video-embed--desktop"},[_vm._t("video-desktop")],2)]):_vm._e(),(_vm.useOverlay && _vm.overlayColor && _vm.overlayAlpha !== null)?_c('div',{staticClass:"color-overlay"}):_vm._e(),_c('article',[_c('div',{class:['content-container', _vm.alignmentClass]},[(
          (_vm.viewport.isMobile && _vm.$slots['title-mobile']) ||
            (!_vm.viewport.isMobile && _vm.$slots['title-desktop'])
        )?_c('div',{class:['title-container', { 'text-shadow': _vm.textShadow }]},[(_vm.viewport.isMobile)?[_vm._t("title-mobile")]:[_vm._t("title-desktop")]],2):_vm._e(),(
          (_vm.viewport.isMobile && _vm.$slots['text-mobile']) ||
            (!_vm.viewport.isMobile && _vm.$slots['text-desktop'])
        )?_c('div',{class:['text-container', { 'text-shadow': _vm.textShadow }]},[(_vm.viewport.isMobile)?[_vm._t("text-mobile")]:[_vm._t("text-desktop")]],2):_vm._e(),_c('div',{class:[
          'button-container',
          { 'text-shadow': _vm.textShadow },
          { 'transparent-buttons': _vm.transparentButtons }
        ]},[_vm._t("button")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }