<template>
  <v-slide class="v-announce-step">
    <v-text class="v-announce-step__title" heading size="medium">{{
      title
    }}</v-text>
    <img :src="imgSrc" :alt="alt" class="v-announce-step__image" />
    <div class="v-announce-step__description">
      <slot></slot>
    </div>
  </v-slide>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-announce-step {
  display: flex;
  flex-direction: column;
  img {
    display: block;
    width: 100%;
  }

  > :not(code) ~ :not(code) {
    margin-top: $spacer * 2;
  }

  &__image {
    flex: 1;
    min-height: 250px;
    margin: 2rem 0;
  }

  &__title {
    max-height: 40px;
    text-align: start;
    align-self: flex-start;
  }
  &__description {
    min-height: 110px;
    flex: 1;
    text-align: start;
    align-self: flex-start;
  }
}
</style>
